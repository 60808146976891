<template>
  <el-dialog
    :model-value="modelValue"
    custom-class="preview_dialog"
    title=""
    :before-close="handleClose"
    :width="900"
    :append-to-body="true"
    :close-on-click-modal="false"
  >
    <div
      v-loading="ctrl.loading"
      class="video_body_content"
    >
      <div
        id="resource_player"
        ref="videoBox"
        class="video_win"
      />
    </div>
  </el-dialog>
</template>

<script>
import { reactive, ref, toRefs } from 'vue'
import { materialPreView } from '@/api/paper.js'
export default defineComponent({
  name: 'Preview',
  props: {
    modelValue: {
      type: Boolean,
      default: false
    },
    id: {
      type: String,
      default: ''
    }
  },
  setup (props, { emit }) {
    const { id, modelValue } = toRefs(props)
    const videoBox = ref(null)
    const opts = reactive({
      imageList: []
    })
    const map = reactive({
      resource_player: null,
      vodPlatform: 2,
      userInfo: {}
    })
    const ctrl = reactive({
      loading: false
    })
    const handleClose = () => {
      if (map.resource_player) {
        map.resource_player.destroy()
        map.resource_player = null
      }
      emit('close')
    }
    const getView = () => {
      ctrl.loading = true
      materialPreView({ id: id.value }).then(res => {
        map.vodPlatform = res.vodPlatform
        if (res.videoConfig) {
          const c = JSON.parse(res.videoConfig)
          res = {
            ...res,
            ...c,
            videoConfig: c
          }
        }
        if (res.vodPlatform === 1 || res.vodPlatform === 4 || res.vodPlatform === 10) {
          play(res)
        } else if (res.vodPlatform === 2) {
          baiJiaYunPlay(res)
        } if (res.vodPlatform === 3) {
          if (res.videoConfig) {
            ccPlay(res.videoConfig)
          }
        }
        ctrl.loading = false
      })
    }

    // 加载CCjs
    const loadCCScript = (url, callback) => {
      const script = document.createElement('script')
      script.type = 'text/javascript'
      // 处理IE
      if (script.readyState) {
        script.onreadystatechange = function () {
          if (script.readyState === 'loaded' || script.readyState === 'complete') {
            script.onreadystatechange = null
            callback()
          }
        }
      } else {
        // 处理其他浏览器的情况
        script.onload = function () {
          callback()
        }
      }
      script.src = url
      document.body.append(script)
    }

    const ccPlay = (videoConfig) => {
      const playerDom = document.getElementById('resource_player')
      playerDom.innerHTML = ''
      // 获得场景 CC
      if (window.ccH5PlayerJsLoaded) {
        map.resource_player = window.createCCH5Player({
          vid: videoConfig.vid,
          vc: videoConfig.vc,
          siteid: videoConfig.siteid,
          autoStart: true,
          width: '100%',
          height: '100%',
          parentNode: playerDom
        })
      }
      window.onCCH5PlayerLoaded = () => {
        window.ccH5PlayerJsLoaded = true
        map.resource_player = window.createCCH5Player({
          vid: videoConfig.vid,
          vc: videoConfig.vc,
          siteid: videoConfig.siteid,
          autoStart: true,
          width: '100%',
          height: '100%',
          parentNode: playerDom
        })
      }
      loadCCScript(`https://p.bokecc.com/player?siteid=${videoConfig.siteid}&newversion=true`, () => {
        console.log('加载成功')
      })
    }

    const baiJiaYunPlay = (data) => {
      const _that = map
      const box = videoBox.value
      let marquee = null
      if (data.vodMarqueeContent) {
        marquee = {
          displayMode: 'roll', // 展示形式滚动roll 或者闪烁 blink
          rollDuration: 10, // 滚动间隔时长 单位秒
          blinkDuration: 10, // 闪烁间隔时长 单位秒
          type: 1, // 1 读取value设置的固定值 2 读取 user.name 昵称
          count: 1, // 跑马灯单双数 1 单数 2 双数
          color: '#ffffff', // 跑马灯颜色
          fontOpacity: 1, // 跑马灯透明度 0 - 1
          fontSize: 20, // 跑马灯字体大小 单位px
          fontBold: 0, // 跑马灯文字加粗 0 不加粗 1 加粗
          backgroundColor: '#999999', // 跑马灯背景色 默认#1795ff
          backgroundOpacity: 0.4, // 跑马灯背景透明度 0 - 1
          position: 'random', // 跑马灯位置 random 随机 top 顶部 bottom 底部
          value: data.vodMarqueeContent // 跑马灯固定值,
        }
      }
      document.querySelector('#resource_player').innerHTML = ''
      _that.resource_player = new BPlayer({
        container: document.querySelector('#resource_player'),
        width: box.offsetWidth,
        height: 500,
        token: data.token,
        autoplay: false,
        vid: data.vid,
        playbackRates: [],
        showCurrentTimeDisplay: true,
        showDurationDisplay: true,
        disableSeek: 0, // 是否禁止拖拽， 0：可以， 1：禁止
        user_name: _that.userInfo.userName, // 用户名， 主要用于数据统计
        user_number: _that.userInfo.userNo, // 用户id， 主要用于数据统计
        plugins: marquee ? { marquee } : {}
      })
      setTimeout(() => {
        _that.resource_player.play()
      }, 500)
    }
    const play = (data, startTime = 0) => {
      const box = videoBox.value
      if (!box) {
        play(data, startTime)
        return
      }
      if (map.resource_player) {
        const param = {
          watchStartTime: startTime || 0,
          width: box.offsetWidth,
          height: 500,
          autoplay: false
        }
        if (data.vodPlatform === 4) {
          param.url = data.videoUrl
        } else {
          param.ts = data.ts
          param.sign = data.sign
          param.vid = data.vid
          param.playsafe = data.token
          param.code = data.code
        }
        map.resource_player.changeVid(param)
      } else {
        document.querySelector('#resource_player').innerHTML = ''
        const param = {
          wrap: '#resource_player',
          width: box.offsetWidth,
          height: 500,
          history_video_duration: 2,
          forceH5: true,
          hideSwitchPlayer: true,
          autoplay: false,
          speed: true,
          watchStartTime: startTime,
          viewerInfo: {
            viewerId: map.userInfo.userNo,
            viewerName: map.userInfo.userName
          }
        }
        if (data.vodPlatform === 4) {
          param.url = data.videoUrl
        } else {
          param.ts = data.ts
          param.sign = data.sign
          param.vid = data.vid
          param.playsafe = data.token
          param.code = data.code
        }
        map.resource_player = window.polyvPlayer(param)
      }
    }
    if (modelValue.value) {
      if (id.value) {
        getView()
      }
    }
    return {
      opts,
      ctrl,
      modelValue,
      videoBox,
      handleClose
    }
  }
})
</script>
<style lang="scss">
.preview_dialog {
  .el-dialog__footer {
    padding: 0;
  }
}
</style>
<style scoped>
.video_body_content{
  position: relative;
  min-height: 500px;
}
.video_win {
  height: 500px;
}
</style>
